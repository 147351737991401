import types from '@havppen/gql/types'
import { mapMemberPublicFromGql } from '@havppen/types/src/member'
import { FundraisingTargetUnit } from '@havppen/types/src/product'
import { notEmpty } from '@havppen/utils/src/array'
import { CourseBriefProps, CourseOrderType, CourseStatus } from 'src/types/course'
import { mapProductDiscountFromGql } from 'src/types/product'
import {
  productMemberTagRestrictionFilterTypes,
  productMemberTagRestrictionTypes,
  productPurchasableTypes,
  productVisibilityTypes,
} from 'src/types/productVisibility'
import { getDiscount } from 'src/utils/discount'
import { NIL as NIL_UUID } from 'uuid'

export type CoursesQueryOptions = {
  limit?: number
  offset?: number
  status?: CourseStatus
  statuses?: CourseStatus[]
  categorySlug?: string | null
  categoryId?: string | null
  instructorId?: string
  courseId?: string
  courseIds?: string[]
  couponId?: string | null
  courseContentId?: string
  search?: string | null
  orderBy?: CourseOrderType
  currentMemberId?: string
  isAuthenticated?: boolean
}

export const mapCoursesQueryOptionsToGqlVariables = (options: CoursesQueryOptions, params?: { appId?: string }) => {
  const orderBy: types.course_order_by[] | undefined =
    options?.orderBy === 'fundraisingDueAt'
      ? [{ fundraising_due_at: 'desc' as types.order_by }, { position: 'desc' as types.order_by }]
      : options?.orderBy === 'hot'
      ? [
          { product_possession_summary: { possession_count: 'desc' as types.order_by } },
          { position: 'desc' as types.order_by },
        ]
      : options?.orderBy === 'recommend'
      ? [{ position: 'desc' as types.order_by }]
      : options?.orderBy === 'latest'
      ? [{ online_at: 'desc_nulls_last' as types.order_by }]
      : [{ position: 'asc_nulls_last' as types.order_by }]

  const courseVisibilityQueries: types.InputMaybe<types.course_bool_exp[]> = [
    {
      _not: {
        course_visibility: {},
      },
    },
    {
      _not: {
        course_visibility: {
          visibility_type: { _in: [productVisibilityTypes.authOnly, productVisibilityTypes.whitelist] },
        },
      },
    },
  ]
  if (options?.isAuthenticated) {
    courseVisibilityQueries.push({
      course_visibility: {
        visibility_type: { _eq: productVisibilityTypes.authOnly },
      },
    })
  }
  if (options?.currentMemberId && options?.currentMemberId !== NIL_UUID) {
    courseVisibilityQueries.push({
      course_visibility: {
        visibility_type: { _eq: productVisibilityTypes.whitelist },
        product_member_tag_restrictions: {
          type: { _eq: productMemberTagRestrictionTypes.visibility },
          filter_type: { _eq: productMemberTagRestrictionFilterTypes.include },
          member_tag_members: { member_id: { _eq: options.currentMemberId } },
        },
      },
    })
  }

  const variables: types.GET_COURSESVariables = {
    limit: options?.limit,
    offset: options?.offset,
    condition: {
      app_id: params?.appId ? { _eq: params.appId } : undefined,
      is_private: { _eq: false },
      status: options?.status
        ? { _eq: options.status }
        : options?.statuses
        ? { _in: options.statuses }
        : options?.orderBy === 'fundraisingDueAt'
        ? { _in: ['fundraising', 'preorder', 'coming_soon'] }
        : undefined,
      id: options?.courseIds ? { _in: options.courseIds } : options.courseId ? { _eq: options.courseId } : undefined,
      course_categories: options?.categorySlug
        ? { category: { slug: { _eq: options.categorySlug } } }
        : options?.categoryId
        ? { category: { id: { _eq: options.categoryId } } }
        : undefined,
      course_instructors: options?.instructorId ? { member_id: { _eq: options.instructorId } } : undefined,
      product: options?.couponId ? { coupon_products: { coupon_id: { _eq: options.couponId } } } : undefined,
      course_chapters: options.courseContentId
        ? { course_contents: { id: { _eq: options.courseContentId } } }
        : undefined,
      deleted_at: { _is_null: true },
      online_at: { _lte: 'now()' },
      _and: [
        { _or: [{ offline_at: { _is_null: true } }, { offline_at: { _gte: 'now()' } }] },
        ...(options?.search
          ? [
              {
                _or: [
                  { title: { _ilike: `%${options.search}%` } },
                  { course_instructors: { member: { name: { _ilike: `%${options.search}%` } } } },
                  { course_instructors: { member: { display_name: { _ilike: `%${options.search}%` } } } },
                ],
              },
            ]
          : []),
      ],
      _or: courseVisibilityQueries,
    },
    orderBy,
  }
  return variables
}

export const formatGqlCourses = (
  data: types.GET_COURSES | undefined,
  defaultAvatarUrl?: string | null,
): CourseBriefProps[] => {
  return (data?.course || []).map(course => {
    const status = course.status as CourseStatus
    const listPrice = course.list_price
    const preorderPrice = course.preorder_price
    const productDiscounts = mapProductDiscountFromGql(course.course_discounts)
    const { discountPrice: salePrice } = getDiscount({
      productDiscounts,
      listPrice,
      defaultSalePrice: course.sale_price,
      defaultFundraisingPrice: course.fundraising_price,
      defaultPreorderPrice: preorderPrice,
      status,
    })

    return {
      id: course.id,
      path: `/course/${course.slug ?? course.id}`,
      title: course.title,
      abstract: course.abstract,
      position: course.position,
      slug: course.slug,
      featuredImageUrl: course.featured_image_url,
      coverVideoUrl: course.cover_video_url,
      isPrivate: course.is_private,
      isSubscription: course.is_subscription,
      listPrice,
      salePrice,
      status,
      onlineAt: course.online_at ? new Date(course.online_at) : null,
      categories: course.course_categories.map(course_category => course_category.category),

      instructors: course.course_instructors
        .map(course_instructor => mapMemberPublicFromGql(course_instructor.member, { defaultAvatarUrl }))
        .filter(notEmpty),

      fundraisingPricingMode: course.fundraising_pricing_mode,
      fundraisingTargetUnit: course.fundraising_target_unit as FundraisingTargetUnit,
      fundraisingTargetAmount: course.fundraising_target_amount,
      fundraisingPrice: course.fundraising_price,
      fundraisingStartAt: course.fundraising_start_at ? new Date(course.fundraising_start_at) : null,
      fundraisingDueAt: course.fundraising_due_at ? new Date(course.fundraising_due_at) : null,
      preorderPrice: course.preorder_price,
      preorderDueAt: course.preorder_due_at ? new Date(course.preorder_due_at) : null,
      courseDiscounts: productDiscounts,

      purchaseCount: course.product_possession_summary?.possession_count || 0,
      totalSales: course.course_sales?.sum || 0,
      contentDuration: Math.round((course.course_durations_aggregate.aggregate?.sum?.duration ?? 0) / 60),
      participantLimit: course.participant_limit,

      reviewCount: course.product_review_summary?.review_count ?? null,
      averageRating: course.product_review_summary?.average_rating ?? null,

      styleAttributes: course.style_attributes,
      metadata: course.metadata,

      isPriceHidden: course.style_attributes?.isPriceHidden ?? false,
      isAuthorInfoHidden: course.style_attributes?.isAuthorInfoHidden ?? false,
      isParticipantCountHidden: course.style_attributes?.isParticipantCountHidden ?? false,
      isDiscountCountdownVisible: course.style_attributes?.isDiscountCountdownVisible ?? false,

      visibilityType: (course.course_visibility?.visibility_type ??
        productVisibilityTypes.public) as productVisibilityTypes,
      purchasableType: (course.course_visibility?.purchasable_type ??
        productPurchasableTypes.public) as productPurchasableTypes,
    }
  })
}
