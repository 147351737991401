'use client'

import { gql, useQuery } from '@apollo/client'
import types from '@havppen/gql/types'
import { mapMemberPublicFromGql } from '@havppen/types/src/member'
import { FundraisingTargetUnit } from '@havppen/types/src/product'
import { notEmpty } from '@havppen/utils/src/array'
import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useApp } from 'src/contexts/AppContext'
import { useAuth } from 'src/contexts/AuthContext'
import {
  GET_COURSE,
  GET_COURSES,
  GET_COURSE_BRIEF_CHAPTERS,
  GET_COURSE_COLLECTIONS,
  GET_COURSE_CONTENT,
  GET_COURSE_CONTENT_BRIEF,
  GET_COURSE_CONTENT_PUBLIC_DETAILS,
  GET_COURSE_DETAIL,
  GET_COURSE_POSSESSION_COUNT,
  GET_COURSE_QAS,
  GET_EVALUATIONS,
  GET_PURCHASED_COURSES,
} from 'src/gql/course'
import {
  CourseBriefProps,
  CourseChapterBriefProps,
  CourseChapterContentBriefProps,
  CourseContentBriefProps,
  CourseContentDetailProps,
  CourseContentProps,
  CourseContentTimecodeProps,
  CourseContentType,
  CourseDetailProps,
  CourseOrderType,
  CourseProps,
  CoursePurchasedProps,
  CourseQA,
  CourseStatus,
} from 'src/types/course'
import { mapProductDiscountFromGql } from 'src/types/product'
import { productPurchasableTypes, productVisibilityTypes } from 'src/types/productVisibility'
import { getDiscount } from 'src/utils/discount'
import { NIL as NIL_UUID, validate as isUUID } from 'uuid'
import { CoursesQueryOptions, formatGqlCourses, mapCoursesQueryOptionsToGqlVariables } from './course'

export const useCourse = (courseIdOrSlug: string) => {
  const { defaultAvatarUrl } = useApp()
  const isCourseId = isUUID(courseIdOrSlug)
  const { loading, error, data, refetch } = useQuery<types.GET_COURSE, types.GET_COURSEVariables>(GET_COURSE, {
    variables: {
      condition: {
        id: isCourseId ? { _eq: courseIdOrSlug } : undefined,
        slug: isCourseId ? undefined : { _eq: courseIdOrSlug },
      },
    },
  })

  const course: CourseProps | null = useMemo(() => {
    if (!data || data.course.length === 0) {
      return null
    }

    return data.course.map(course => {
      const status = course.status as CourseStatus
      const listPrice = course.list_price
      const preorderPrice = course.preorder_price
      const productDiscounts = mapProductDiscountFromGql(course.course_discounts)
      const { discountPrice: salePrice } = getDiscount({
        productDiscounts,
        listPrice,
        defaultSalePrice: course.sale_price,
        defaultFundraisingPrice: course.fundraising_price,
        defaultPreorderPrice: preorderPrice,
        status,
      })

      return {
        id: course.id,
        path: `/course/${course.slug ?? course.id}`,
        title: course.title,
        abstract: course.abstract,
        position: course.position,
        slug: course.slug,
        featuredImageUrl: course.featured_image_url,
        coverVideoUrl: course.cover_video_url,
        isPrivate: course.is_private,
        isSubscription: course.is_subscription,
        listPrice,
        salePrice,
        status,
        onlineAt: course.online_at ? new Date(course.online_at) : null,
        categories: course.course_categories.map(course_category => course_category.category),

        metadata: course.metadata,
        styleAttributes: course.style_attributes,

        fundraisingPricingMode: course.fundraising_pricing_mode,
        fundraisingTargetUnit: course.fundraising_target_unit as FundraisingTargetUnit,
        fundraisingTargetAmount: course.fundraising_target_amount,
        fundraisingPrice: course.fundraising_price,
        fundraisingStartAt: course.fundraising_start_at ? new Date(course.fundraising_start_at) : null,
        fundraisingDueAt: course.fundraising_due_at ? new Date(course.fundraising_due_at) : null,
        preorderPrice,
        preorderDueAt: course.preorder_due_at ? new Date(course.preorder_due_at) : null,

        isPriceHidden: course.style_attributes?.isPriceHidden ?? false,
        isAuthorInfoHidden: course.style_attributes?.isAuthorInfoHidden ?? false,
        isParticipantCountHidden: course.style_attributes?.isParticipantCountHidden ?? false,
        isDiscountCountdownVisible: course.style_attributes?.isDiscountCountdownVisible ?? false,

        instructors: course.course_instructors
          .map(course_instructor => mapMemberPublicFromGql(course_instructor.member, { defaultAvatarUrl }))
          .filter(notEmpty),
        assistants: course.course_assistants
          .map(course_assistant => mapMemberPublicFromGql(course_assistant.member, { defaultAvatarUrl }))
          .filter(notEmpty),

        chapterCount: course.course_chapters.length,
        chapterContentCount: course.course_chapters.reduce((sum, course_chapter) => {
          return sum + (course_chapter.course_content_publics_aggregate.aggregate?.count ?? 0)
        }, 0),
        assignmentCount: course.course_chapters.reduce((sum, course_chapter) => {
          return sum + (course_chapter.course_assignment_contents_aggregate.aggregate?.count ?? 0)
        }, 0),
        materialCount: course.course_chapters.reduce((sum, course_chapter) => {
          return sum + (course_chapter.course_material_contents_aggregate.aggregate?.count ?? 0)
        }, 0),

        courseDiscounts: productDiscounts,
        purchaseCount: course.product_possession_summary?.possession_count || 0,
        totalSales: course.course_sales?.sum || 0,
        participantLimit: course.participant_limit,
        contentDuration: Math.round((course.course_durations_aggregate.aggregate?.sum?.duration ?? 0) / 60),

        reviewCount: course.product_review_summary?.review_count ?? null,
        averageRating: course.product_review_summary?.average_rating ?? null,
        seoAttributes: course.seo_attributes,

        visibilityType: (course.course_visibility?.visibility_type ??
          productVisibilityTypes.public) as productVisibilityTypes,
        purchasableType: (course.course_visibility?.purchasable_type ??
          productPurchasableTypes.public) as productPurchasableTypes,
      }
    })[0]
  }, [data, defaultAvatarUrl])

  return {
    loadingCourse: loading,
    errorCourse: error,
    course,
    refetchCourse: refetch,
  }
}

export const useCourseDetail = (courseIdOrSlug: string) => {
  const isCourseId = isUUID(courseIdOrSlug)
  const { loading, error, data, refetch } = useQuery<types.GET_COURSE_DETAIL, types.GET_COURSE_DETAILVariables>(
    GET_COURSE_DETAIL,
    {
      context: { important: true },
      variables: {
        condition: {
          id: isCourseId ? { _eq: courseIdOrSlug } : undefined,
          slug: isCourseId ? undefined : { _eq: courseIdOrSlug },
        },
      },
    },
  )

  const courseDetail: CourseDetailProps | null =
    loading || error || !data || data.course.length === 0
      ? null
      : data.course.map(course => ({
          id: course.id,
          content: course.content,
          informationData: {
            youCanLearnVisible: course.information_data?.you_can_learn_visible ?? true,
            beforeCoursePrepareVisible: course.information_data?.before_course_prepare_visible ?? true,
            ...course.information_data,
          },
        }))[0]
  return {
    loadingCourseDetail: loading,
    errorCourseDetail: error,
    courseDetail,
    refetchCourseDetail: refetch,
  }
}

export const useCourses = (options?: CoursesQueryOptions) => {
  const { isAuthenticated, currentMemberId } = useAuth()
  const { defaultAvatarUrl, id: appId } = useApp()

  const { loading, error, data, refetch } = useQuery<types.GET_COURSES, types.GET_COURSESVariables>(GET_COURSES, {
    variables: mapCoursesQueryOptionsToGqlVariables(
      {
        isAuthenticated,
        currentMemberId,
        ...options,
      },
      { appId },
    ),
    context: { important: true },
  })

  const courses: CourseBriefProps[] = useMemo(() => {
    return formatGqlCourses(data, defaultAvatarUrl)
  }, [data, defaultAvatarUrl])

  const courseCount = useMemo(() => {
    return data?.course_aggregate.aggregate?.count || 0
  }, [data])

  return {
    loadingCourses: loading,
    errorCourses: error,
    courses,
    refetchCourses: refetch,
    courseCount,
  }
}

export const useCoursesPurchased = (
  memberId: string,
  options?: {
    limit?: number
    offset?: number
    search?: string | null
    orderBy?: CourseOrderType
    status?: CourseStatus
    hasAssignmentSubmitted?: boolean
  },
) => {
  const [isNoMore, setIsNoMore] = useState(false)
  const { defaultAvatarUrl } = useApp()
  const condition: types.course_bool_exp = useMemo(
    () => ({
      status: options?.status ? { _eq: options.status } : undefined,
      course_chapters: options?.hasAssignmentSubmitted
        ? { course_contents: { course_content_assignment_works: { member_id: { _eq: memberId } } } }
        : undefined,
      _or: options?.search
        ? [
            { title: { _ilike: `%${options.search}%` } },
            { course_instructors: { member: { name: { _ilike: `%${options.search}%` } } } },
            { course_instructors: { member: { display_name: { _ilike: `%${options.search}%` } } } },
          ]
        : undefined,
      course_possessions: { member_id: { _eq: memberId } },
    }),
    [memberId, options],
  )

  const orderBy: types.course_order_by[] | undefined = useMemo(
    () =>
      options?.orderBy === 'fundraisingDueAt'
        ? [{ fundraising_due_at: 'desc' as types.order_by }, { position: 'desc' as types.order_by }]
        : options?.orderBy === 'hot'
        ? [
            { product_possession_summary: { possession_count: 'desc' as types.order_by } },
            { position: 'desc' as types.order_by },
          ]
        : options?.orderBy === 'recommend'
        ? [{ position: 'desc' as types.order_by }]
        : options?.orderBy === 'latest'
        ? [{ published_at: 'desc' as types.order_by }]
        : options?.orderBy === 'createdAt'
        ? [{ updated_at: 'desc' as types.order_by }]
        : undefined,
    [options?.orderBy],
  )

  const { loading, error, data, refetch, fetchMore } = useQuery<
    types.GET_PURCHASED_COURSES,
    types.GET_PURCHASED_COURSESVariables
  >(GET_PURCHASED_COURSES, {
    skip: memberId === NIL_UUID,
    variables: {
      memberId,
      limit: options?.limit,
      offset: options?.offset,
      orderBy,
      condition,
    },
  })

  const coursesPurchased: CoursePurchasedProps[] = useMemo(() => {
    if (!data) {
      return []
    } else {
      return data.course.map(course => {
        const status = course.status as CourseStatus
        const listPrice = course.list_price
        const preorderPrice = course.preorder_price
        const productDiscounts = mapProductDiscountFromGql(course.course_discounts)
        const { discountPrice: salePrice } = getDiscount({
          productDiscounts,
          listPrice,
          defaultSalePrice: course.sale_price,
          defaultFundraisingPrice: course.fundraising_price,
          defaultPreorderPrice: preorderPrice,
          status,
        })

        const course_possessions = [...course.course_possessions]
        course_possessions.sort((a, b) => {
          if (a.trial_expired_at && b.trial_expired_at) {
            return b.trial_expired_at.localeCompare(a.trial_expired_at)
          } else if (a.trial_expired_at === null) {
            return -1
          } else if (b.trial_expired_at === null) {
            return 1
          } else {
            return 0
          }
        })

        return {
          id: course.id,
          path: `/course/${course.slug ?? course.id}`,
          title: course.title,
          abstract: course.abstract,
          position: course.position,
          slug: course.slug,
          featuredImageUrl: course.featured_image_url,
          coverVideoUrl: course.cover_video_url,
          isPrivate: course.is_private,
          isSubscription: course.is_subscription,
          listPrice,
          salePrice,
          status,
          onlineAt: course.online_at ? new Date(course.online_at) : null,
          categories: course.course_categories.map(course_category => course_category.category),

          isPriceHidden: course.style_attributes?.isPriceHidden ?? false,
          isAuthorInfoHidden: course.style_attributes?.isAuthorInfoHidden ?? false,
          isParticipantCountHidden: course.style_attributes?.isParticipantCountHidden ?? false,
          isDiscountCountdownVisible: course.style_attributes?.isDiscountCountdownVisible ?? false,

          instructors: course.course_instructors
            .map(course_instructor => mapMemberPublicFromGql(course_instructor.member, { defaultAvatarUrl }))
            .filter(notEmpty),

          fundraisingPricingMode: course.fundraising_pricing_mode,
          fundraisingTargetUnit: course.fundraising_target_unit as FundraisingTargetUnit,
          fundraisingTargetAmount: course.fundraising_target_amount,
          fundraisingPrice: course.fundraising_price,
          fundraisingStartAt: course.fundraising_start_at ? new Date(course.fundraising_start_at) : null,
          fundraisingDueAt: course.fundraising_due_at ? new Date(course.fundraising_due_at) : null,
          preorderPrice: course.preorder_price,
          preorderDueAt: course.preorder_due_at ? new Date(course.preorder_due_at) : null,

          courseDiscounts: productDiscounts,
          purchaseCount: course.product_possession_summary?.possession_count || 0,
          totalSales: course.course_sales?.sum || 0,
          contentDuration: Math.round((course.course_durations_aggregate.aggregate?.sum?.duration ?? 0) / 60),
          participantLimit: course.participant_limit,

          reviewCount: course.product_review_summary?.review_count ?? null,
          averageRating: course.product_review_summary?.average_rating ?? null,
          totalProgressPercentage: course.course_member_progresses?.[0]?.progress_percentage ?? null,
          isExpired: course_possessions[0]?.is_expired ?? false,
          expiredAt: course_possessions[0]?.trial_expired_at ? new Date(course_possessions[0].trial_expired_at) : null,

          styleAttributes: course.style_attributes,
          metadata: course.metadata,

          visibilityType: (course.course_visibility?.visibility_type ??
            productVisibilityTypes.public) as productVisibilityTypes,
          purchasableType: (course.course_visibility?.purchasable_type ??
            productPurchasableTypes.public) as productPurchasableTypes,
        }
      })
    }
  }, [data, defaultAvatarUrl])

  const coursesPurchaseCount = useMemo(() => data?.course_aggregate.aggregate?.count || 0, [data])
  useEffect(() => {
    if (options?.limit && options.limit >= coursesPurchaseCount) {
      setIsNoMore(true)
    } else if (!options?.limit) {
      setIsNoMore(true)
    }
  }, [coursesPurchaseCount, options?.limit])

  return {
    loadingCoursesPurchased: loading,
    errorCoursesPurchased: error,
    coursesPurchased,
    refetchCoursesPurchased: async () => {
      setIsNoMore(false)
      await refetch()
    },
    coursesPurchaseCount,
    fetchMoreCoursesPurchaseCount: isNoMore
      ? undefined
      : () =>
          fetchMore({
            variables: { offset: data?.course.length || 0 },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return prev
              }
              if (fetchMoreResult.course.length < (options?.limit || 10)) {
                setIsNoMore(true)
              }
              return {
                ...prev,
                course: [...prev.course, ...fetchMoreResult.course],
              }
            },
          }),
  }
}

export const useCoursePurchaseCount = (courseId: string) => {
  const { loading, error, data, refetch } = useQuery<
    types.GET_COURSE_POSSESSION_COUNT,
    types.GET_COURSE_POSSESSION_COUNTVariables
  >(GET_COURSE_POSSESSION_COUNT, {
    skip: !courseId,
    variables: {
      condition: {
        product_target: { _eq: courseId },
      },
    },
  })

  const coursesPurchaseCount = useMemo(() => data?.product_possession_summary?.[0].possession_count || 0, [data])

  return {
    loadingCoursePurchaseCount: loading,
    errorCoursePurchaseCount: error,
    refetchCoursePurchaseCount: refetch,
    coursesPurchaseCount,
  }
}

export const useCourseCollections = (
  memberId: string,
  options?: { limit?: number; offset?: number; statuses?: CourseStatus[]; excludePurchases?: boolean },
) => {
  const [isNoMore, setIsNoMore] = useState(false)
  const { defaultAvatarUrl } = useApp()
  const { loading, error, data, refetch, fetchMore } = useQuery<
    types.GET_COURSE_COLLECTIONS,
    types.GET_COURSE_COLLECTIONSVariables
  >(GET_COURSE_COLLECTIONS, {
    variables: {
      limit: options?.limit,
      offset: options?.offset,
      condition: {
        member_id: { _eq: memberId },
        course: {
          status: options?.statuses ? { _in: options.statuses } : undefined,
          course_possessions: options?.excludePurchases ? {} : undefined,
        },
      },
    },
  })

  const courseCollections: CourseBriefProps[] =
    loading || error || !data || data.course_collection.length === 0
      ? []
      : data.course_collection
          .map(course_collection => course_collection.course)
          .filter(notEmpty)
          .map(course => {
            const status = course.status as CourseStatus
            const listPrice = course.list_price
            const preorderPrice = course.preorder_price
            const productDiscounts = mapProductDiscountFromGql(course.course_discounts)
            const { discountPrice: salePrice } = getDiscount({
              productDiscounts,
              listPrice,
              defaultSalePrice: course.sale_price,
              defaultFundraisingPrice: course.fundraising_price,
              defaultPreorderPrice: preorderPrice,
              status,
            })

            return {
              id: course.id,
              path: `/course/${course.slug ?? course.id}`,
              title: course.title,
              abstract: course.abstract,
              position: course.position,
              slug: course.slug,
              featuredImageUrl: course.featured_image_url,
              coverVideoUrl: course.cover_video_url,
              isPrivate: course.is_private,
              isSubscription: course.is_subscription,
              listPrice,
              salePrice,
              status,
              onlineAt: course.online_at ? new Date(course.online_at) : null,
              categories: course.course_categories.map(course_category => course_category.category),

              instructors: course.course_instructors
                .map(course_instructor => mapMemberPublicFromGql(course_instructor.member, { defaultAvatarUrl }))
                .filter(notEmpty),

              isPriceHidden: course.style_attributes?.isPriceHidden ?? false,
              isAuthorInfoHidden: course.style_attributes?.isAuthorInfoHidden ?? false,
              isParticipantCountHidden: course.style_attributes?.isParticipantCountHidden ?? false,
              isDiscountCountdownVisible: course.style_attributes?.isDiscountCountdownVisible ?? false,

              fundraisingPricingMode: course.fundraising_pricing_mode,
              fundraisingTargetUnit: course.fundraising_target_unit as FundraisingTargetUnit,
              fundraisingTargetAmount: course.fundraising_target_amount,
              fundraisingPrice: course.fundraising_price,
              fundraisingStartAt: course.fundraising_start_at ? new Date(course.fundraising_start_at) : null,
              fundraisingDueAt: course.fundraising_due_at ? new Date(course.fundraising_due_at) : null,
              preorderPrice: course.preorder_price,
              preorderDueAt: course.preorder_due_at ? new Date(course.preorder_due_at) : null,

              courseDiscounts: productDiscounts,
              purchaseCount: course.product_possession_summary?.possession_count || 0,
              totalSales: course.course_sales?.sum || 0,
              contentDuration: Math.round((course.course_durations_aggregate.aggregate?.sum?.duration ?? 0) / 60),
              participantLimit: course.participant_limit,

              reviewCount: course.product_review_summary?.review_count ?? null,
              averageRating: course.product_review_summary?.average_rating ?? null,

              styleAttributes: course.style_attributes,
              metadata: course.metadata,

              visibilityType: (course.course_visibility?.visibility_type ??
                productVisibilityTypes.public) as productVisibilityTypes,
              purchasableType: (course.course_visibility?.purchasable_type ??
                productPurchasableTypes.public) as productPurchasableTypes,
            }
          })

  const courseCollectionCount = data?.course_collection_aggregate.aggregate?.count || 0
  useEffect(() => {
    if (options?.limit && options.limit >= courseCollectionCount) {
      setIsNoMore(true)
    } else if (!options?.limit) {
      setIsNoMore(true)
    }
  }, [courseCollectionCount, options?.limit])

  return {
    loadingCourseCollections: loading,
    errorCourseCollections: error,
    courseCollections,
    refetchCourseCollections: async () => {
      setIsNoMore(false)
      await refetch()
    },
    courseCollectionCount,
    fetchMoreCoursesCollectionCount: isNoMore
      ? undefined
      : () =>
          fetchMore({
            variables: { offset: data?.course_collection.length || 0 },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return prev
              }
              if (fetchMoreResult.course_collection.length < (options?.limit || 10)) {
                setIsNoMore(true)
              }
              return {
                ...prev,
                course_collection: [...prev.course_collection, ...fetchMoreResult.course_collection],
              }
            },
          }),
  }
}
export const useCourseCreations = (memberId: string, options?: { limit?: number; offset?: number }) => {
  const { defaultAvatarUrl, id: appId } = useApp()
  const { loading, error, data, refetch } = useQuery<types.GET_COURSES, types.GET_COURSESVariables>(GET_COURSES, {
    variables: {
      limit: options?.limit,
      offset: options?.offset,
      condition: {
        status: { _in: ['coming_soon', 'published', 'fundraising', 'preorder'] },
        course_instructors: { member_id: { _eq: memberId } },
        app_id: { _eq: appId },
      },
    },
  })

  const courseCreations: CourseBriefProps[] =
    loading || error || !data || data.course.length === 0
      ? []
      : data.course.map(course => {
          const status = course.status as CourseStatus
          const listPrice = course.list_price
          const preorderPrice = course.preorder_price
          const productDiscounts = mapProductDiscountFromGql(course.course_discounts)
          const { discountPrice: salePrice } = getDiscount({
            productDiscounts,
            listPrice,
            defaultSalePrice: course.sale_price,
            defaultFundraisingPrice: course.fundraising_price,
            defaultPreorderPrice: preorderPrice,
            status,
          })

          return {
            id: course.id,
            path: `/course/${course.slug ?? course.id}`,
            title: course.title,
            abstract: course.abstract,
            position: course.position,
            slug: course.slug,
            featuredImageUrl: course.featured_image_url,
            coverVideoUrl: course.cover_video_url,
            isPrivate: course.is_private,
            isSubscription: course.is_subscription,
            listPrice,
            salePrice,
            status,
            onlineAt: course.online_at ? new Date(course.online_at) : null,
            categories: course.course_categories.map(course_category => course_category.category),

            instructors: course.course_instructors
              .map(course_instructor => mapMemberPublicFromGql(course_instructor.member, { defaultAvatarUrl }))
              .filter(notEmpty),

            isPriceHidden: course.style_attributes?.isPriceHidden ?? false,
            isAuthorInfoHidden: course.style_attributes?.isAuthorInfoHidden ?? false,
            isParticipantCountHidden: course.style_attributes?.isParticipantCountHidden ?? false,
            isDiscountCountdownVisible: course.style_attributes?.isDiscountCountdownVisible ?? false,

            fundraisingPricingMode: course.fundraising_pricing_mode,
            fundraisingTargetUnit: course.fundraising_target_unit as FundraisingTargetUnit,
            fundraisingTargetAmount: course.fundraising_target_amount,
            fundraisingPrice: course.fundraising_price,
            fundraisingStartAt: course.fundraising_start_at ? new Date(course.fundraising_start_at) : null,
            fundraisingDueAt: course.fundraising_due_at ? new Date(course.fundraising_due_at) : null,
            preorderPrice: course.preorder_price,
            preorderDueAt: course.preorder_due_at ? new Date(course.preorder_due_at) : null,

            courseDiscounts: productDiscounts,
            purchaseCount: course.product_possession_summary?.possession_count || 0,
            totalSales: course.course_sales?.sum || 0,
            contentDuration: Math.round((course.course_durations_aggregate.aggregate?.sum?.duration ?? 0) / 60),
            participantLimit: course.participant_limit,

            reviewCount: course.product_review_summary?.review_count ?? null,
            averageRating: course.product_review_summary?.average_rating ?? null,

            styleAttributes: course.style_attributes,
            metadata: course.metadata,

            visibilityType: (course.course_visibility?.visibility_type ??
              productVisibilityTypes.public) as productVisibilityTypes,
            purchasableType: (course.course_visibility?.purchasable_type ??
              productPurchasableTypes.public) as productPurchasableTypes,
          }
        })

  const courseCreationCount = data?.course_aggregate.aggregate?.count || 0

  return {
    loadingCourseCreations: loading,
    errorCourseCreations: error,
    courseCreations,
    refetchCourseCreations: refetch,
    courseCreationCount,
  }
}

const courseContentFetcher = async (
  params: [type: string, courseIdOrSlug: string, appId: string, authToken: string | null],
) => {
  const [type, courseIdOrSlug, appId, authToken] = params
  const isCourseId = isUUID(courseIdOrSlug)
  const apiPath = `/api/rest/${type}/${isCourseId ? 'id' : 'slug'}/${courseIdOrSlug}`
  const headers = authToken
    ? { authorization: `Bearer ${authToken}` }
    : {
        'x-hasura-app-id': appId,
        'x-hasura-user-id': NIL_UUID,
        'x-hasura-role': 'anonymous',
      }

  const { data } = await axios.get(`https://${process.env.NEXT_PUBLIC_HASURA_HOST}${apiPath}`, { headers })
  return data
}
export const useCourseBriefChapters = (courseIdOrSlug: string) => {
  const isCourseId = isUUID(courseIdOrSlug)
  const { loading, data, error, refetch } = useQuery<
    types.GET_COURSE_BRIEF_CHAPTERS,
    types.GET_COURSE_BRIEF_CHAPTERSVariables
  >(GET_COURSE_BRIEF_CHAPTERS, {
    variables: {
      condition: {
        id: isCourseId ? { _eq: courseIdOrSlug } : undefined,
        slug: isCourseId ? undefined : { _eq: courseIdOrSlug },
      },
    },
  })

  const courseChapterContents: CourseChapterContentBriefProps[] = useMemo(() => {
    const courseChapterContents =
      data?.course_chapter.map(course_chapter => {
        const courseContents = course_chapter.course_content_publics.map(course_content => ({
          id: course_content.id,
          title: course_content.title,
          type: course_content.type as CourseContentType,
          duration: course_content.duration,
          position: course_content.position ?? 0,
          videoDuration: course_content.video_duration,
          isPrivate: course_content.is_private,
          isVisible: course_content.is_visible,
          createdAt: new Date(course_content.created_at),
          publishedAt: course_content.published_at ? new Date(course_content.published_at) : null,
          attachments: course_content.file_attachments.map(file_attachment => ({
            uid: file_attachment.id,
            name: file_attachment.file_name,
            url: `/a/${file_attachment.id}`,
            size: file_attachment.file_size ?? undefined,
            type: file_attachment.file_type ?? undefined,
          })),
        }))
        courseContents.sort((a, b) => a.position - b.position || a.createdAt.getTime() - b.createdAt.getTime())

        return {
          id: course_chapter.id,
          title: course_chapter.title,
          isPrivate: course_chapter.is_private,
          courseId: course_chapter.course_id,
          position: course_chapter.position,
          createdAt: new Date(course_chapter.created_at),
          courseContents,
        }
      }) || []
    courseChapterContents.sort((a, b) => a.position - b.position || a.createdAt.getTime() - b.createdAt.getTime())

    return courseChapterContents
  }, [data])

  return {
    loadingCourseChapterContents: loading,
    errorCourseChapterContents: error,
    courseChapterContents,
    refetchCourseChapterContents: refetch,
  }
}

export const useCourseContents = (courseIdOrSlug: string) => {
  const isCourseId = isUUID(courseIdOrSlug)
  const { loading, data, error, refetch } = useQuery<
    types.GET_COURSE_CONTENT_PUBLIC_DETAILS,
    types.GET_COURSE_CONTENT_PUBLIC_DETAILSVariables
  >(GET_COURSE_CONTENT_PUBLIC_DETAILS, {
    variables: {
      condition: {
        course_chapter: {
          course_id: isCourseId ? { _eq: courseIdOrSlug } : undefined,
          course: isCourseId ? undefined : { slug: { _eq: courseIdOrSlug } },
        },
        published_at: { _lte: 'now()' },
      },
    },
  })

  const courseContents: CourseContentDetailProps[] = useMemo(
    () =>
      data?.course_content_public.map(course_content_public => ({
        id: course_content_public.id,
        content: course_content_public.content ?? course_content_public.course_content?.content,
        videoUrl: course_content_public.video_url ?? course_content_public.course_content?.video_url,
        captionUrl: course_content_public.caption_url ?? course_content_public.course_content?.caption_url,
        metadata: course_content_public.metadata ?? course_content_public.course_content?.metadata,
        attachments: (course_content_public.course_content?.file_attachments || []).map(file_attachment => ({
          uid: file_attachment.id,
          name: file_attachment.file_name,
          url: `/a/${file_attachment.id}`,
          size: file_attachment.file_size ?? undefined,
          type: file_attachment.file_content_type ?? undefined,
        })),
        timecodes: course_content_public.course_content_timecodes
          .map(course_content_timecode => ({
            id: course_content_timecode.id,
            name: course_content_timecode.name,
            timecode: course_content_timecode.timecode,
          }))
          .sort((a, b) => a.timecode - b.timecode),
      })) || [],
    [data],
  )

  return {
    loadingCourseContents: loading,
    errorCourseContents: error,
    courseContents,
    refetchCourseContents: refetch,
  }
}

export const useCourseContent = (courseContentId = NIL_UUID) => {
  const { loading, error, data, refetch } = useQuery<types.GET_COURSE_CONTENT, types.GET_COURSE_CONTENTVariables>(
    GET_COURSE_CONTENT,
    {
      skip: courseContentId === NIL_UUID,
      variables: { courseContentId: courseContentId },
    },
  )

  const courseContent: (CourseContentProps & { chapter: CourseChapterBriefProps }) | null = useMemo(
    () =>
      data?.course_content_by_pk
        ? {
            id: data.course_content_by_pk.id,
            title: data.course_content_by_pk.title,
            type: data.course_content_by_pk.type as CourseContentType,
            duration: data.course_content_by_pk.duration,
            videoDuration: data.course_content_by_pk.video_duration,
            isPrivate: data.course_content_by_pk.is_private,
            isVisible: data.course_content_by_pk.is_visible,
            position: data.course_content_by_pk.position ?? 0,
            createdAt: new Date(data.course_content_by_pk.created_at),
            publishedAt: data.course_content_by_pk.published_at
              ? new Date(data.course_content_by_pk.published_at)
              : null,
            content: data.course_content_by_pk.content,
            attachments: data.course_content_by_pk.file_attachments.map(file_attachment => ({
              uid: file_attachment.id,
              name: file_attachment.file_name,
              url: `/a/${file_attachment.id}`,
              size: file_attachment.file_size ?? undefined,
              type: file_attachment.file_content_type ?? undefined,
            })),
            videoUrl: data.course_content_by_pk.video_url,
            videoWidth: data.course_content_by_pk.video_width,
            videoHeight: data.course_content_by_pk.video_height,
            captionUrl: data.course_content_by_pk.caption_url,
            metadata: data.course_content_by_pk.metadata,
            timecodes: data.course_content_by_pk.course_content_timecodes
              .map(course_content_timecode => ({
                id: course_content_timecode.id,
                name: course_content_timecode.name,
                timecode: course_content_timecode.timecode,
              }))
              .sort((a, b) => a.timecode - b.timecode),
            chapter: {
              id: data.course_content_by_pk.course_chapter?.id as string,
              title: data.course_content_by_pk.course_chapter?.title as string,
              position: data.course_content_by_pk.course_chapter?.position as number,
              isPrivate: data.course_content_by_pk.course_chapter?.is_private as boolean,
              courseId: data.course_content_by_pk.course_chapter?.course_id as string,
              createdAt: new Date(data.course_content_by_pk.course_chapter?.created_at as string),
            },
            videoSources: [],
            textTracks: [],
          }
        : null,
    [data],
  )
  return {
    loadingCourseContent: loading,
    errorCourseContent: error,
    courseContent,
    refetchCourseContent: refetch,
  }
}

export const useCourseContentBrief = (courseContentId: string) => {
  const { loading, error, data, refetch } = useQuery<
    types.GET_COURSE_CONTENT_BRIEF,
    types.GET_COURSE_CONTENT_BRIEFVariables
  >(GET_COURSE_CONTENT_BRIEF, {
    variables: { courseContentId },
  })

  const courseContentBrief: CourseContentBriefProps | null = useMemo(
    () =>
      loading || error || !data || !data.course_content_by_pk
        ? null
        : {
            id: data.course_content_by_pk.id,
            title: data.course_content_by_pk.title,
            type: data.course_content_by_pk.type as CourseContentType,
            duration: data.course_content_by_pk.duration,
            videoDuration: data.course_content_by_pk.video_duration,
            isPrivate: data.course_content_by_pk.is_private,
            isVisible: data.course_content_by_pk.is_visible,
            position: data.course_content_by_pk.position ?? 0,
            createdAt: new Date(data.course_content_by_pk.created_at),
            publishedAt: data.course_content_by_pk.published_at
              ? new Date(data.course_content_by_pk.published_at)
              : null,
            attachments: data.course_content_by_pk.file_attachments.map(file_attachment => ({
              uid: file_attachment.id,
              name: file_attachment.file_name,
              url: `/a/${file_attachment.id}`,
              size: file_attachment.file_size ?? undefined,
              type: file_attachment.file_type ?? undefined,
            })),
          },
    [data, error, loading],
  )
  return {
    loadingCourseContentBrief: loading,
    errorCourseContentBrief: error,
    courseContentBrief,
    refetchCourseContentBrief: refetch,
  }
}

export const useCourseContentTimecodes = (courseContentId: string) => {
  const { loading, error, data, refetch } = useQuery<
    types.GET_COURSE_CONTENT_TIMECODES,
    types.GET_COURSE_CONTENT_TIMECODESVariables
  >(
    gql`
      query GET_COURSE_CONTENT_TIMECODES($courseContentId: uuid!) {
        course_content_timecode(where: { course_content_id: { _eq: $courseContentId } }) {
          id
          name
          timecode
          created_at
        }
      }
    `,
    {
      variables: { courseContentId },
    },
  )

  const courseContentTimecodes: CourseContentTimecodeProps[] =
    loading || error || !data || data.course_content_timecode.length === 0
      ? []
      : data.course_content_timecode.map(course_content_timecode => ({
          id: course_content_timecode.id,
          name: course_content_timecode.name,
          timecode: course_content_timecode.timecode,
        }))

  return {
    loadingCourseContentTimecodes: loading,
    errorCourseContentTimecodes: error,
    courseContentTimecodes,
    refetchCourseContentTimecodes: refetch,
  }
}

export const useEvaluations = ({ limit = 10 }: { limit?: number }) => {
  const { defaultAvatarUrl } = useApp()
  const { loading, error, data, refetch } = useQuery<types.GET_EVALUATIONS, types.GET_EVALUATIONSVariables>(
    GET_EVALUATIONS,
    {
      variables: {
        limit,
      },
    },
  )

  const courseEvaluations =
    loading || error || !data || data.course_evaluation.length === 0
      ? []
      : data.course_evaluation.map(course_evaluation => ({
          id: course_evaluation.id,
          grades: course_evaluation.grades,
          title: course_evaluation.title,
          content: course_evaluation.content,
          member: mapMemberPublicFromGql(course_evaluation.member, { defaultAvatarUrl }),
          createdAt: new Date(course_evaluation.created_at),
        }))

  return {
    loadingCourseEvaluations: loading,
    errorCourseEvaluations: error,
    courseEvaluations,
    refetchCourseEvaluations: refetch,
  }
}

export const useCourseQAs = (courseIdOrSlug: string, limit?: number) => {
  const { defaultAvatarUrl } = useApp()
  const isCourseId = isUUID(courseIdOrSlug)
  const [isNoMore, setIsNoMore] = useState(false)
  const { loading, error, data, refetch, fetchMore } = useQuery<types.GET_COURSE_QAS, types.GET_COURSE_QASVariables>(
    GET_COURSE_QAS,
    {
      variables: {
        condition: {
          course: {
            id: isCourseId ? { _eq: courseIdOrSlug } : undefined,
            slug: isCourseId ? undefined : { _eq: courseIdOrSlug },
          },
        },
        limit: limit || 10,
      },
    },
  )

  const courseQAs: CourseQA[] =
    loading || error || !data || data.course_qa.length === 0
      ? []
      : data.course_qa.map(course_qa => ({
          id: course_qa.id,
          content: course_qa.content,
          member: mapMemberPublicFromGql(course_qa.member, { defaultAvatarUrl }),
          createdAt: course_qa.created_at ? new Date(course_qa.created_at) : null,
          courseQAs: course_qa.course_qas.map(sub_course_qa => ({
            id: sub_course_qa.id,
            content: sub_course_qa.content,
            member: mapMemberPublicFromGql(sub_course_qa.member, { defaultAvatarUrl }),
            createdAt: sub_course_qa.created_at ? new Date(sub_course_qa.created_at) : null,
          })),
        }))

  const courseQAIds = courseQAs.map(courseQA => courseQA.id)
  useEffect(() => {
    if (!loading && courseQAs.length < (limit || 10)) {
      setIsNoMore(true)
    }
  }, [courseQAs.length, limit, loading])

  return {
    loadingCourseQAs: loading,
    errorCourseQAs: error,
    courseQAs,
    refetchCourseQAs: refetch,
    fetchMoreCourseQAs: isNoMore
      ? undefined
      : () =>
          typeof fetchMore !== 'undefined'
            ? fetchMore?.({
                variables: {
                  condition: {
                    course: {
                      id: isCourseId ? { _eq: courseIdOrSlug } : undefined,
                      slug: isCourseId ? undefined : { _eq: courseIdOrSlug },
                    },
                    id: { _nin: courseQAIds },
                  },
                  limit: limit || 10,
                },
                updateQuery(prev, { fetchMoreResult }) {
                  if (!fetchMoreResult) {
                    return prev
                  }
                  if (fetchMoreResult.course_qa.length < 1) {
                    setIsNoMore(true)
                  }
                  return {
                    ...prev,
                    course_qa: [...prev.course_qa, ...fetchMoreResult.course_qa],
                  }
                },
              })
            : undefined,
  }
}

export type CourseContentProgressProps = {
  id: string
  courseContentId: string
  progressPercentage: number
  lastProgressSeconds: number
  isLastOpen: boolean
}
export const useCourseContentProgress = (options: { courseContentId?: string; courseId?: string }) => {
  const { currentMemberId } = useAuth()

  const { loading, error, data, refetch } = useQuery<
    types.GET_COURSE_CONTENT_PROGRESS,
    types.GET_COURSE_CONTENT_PROGRESSVariables
  >(
    gql`
      query GET_COURSE_CONTENT_PROGRESS($condition: course_content_progress_bool_exp!) {
        course_content_progress(where: $condition, limit: 1) {
          id
          course_content_id
          progress_percentage
          last_progress_seconds
          is_last_open
        }
      }
    `,
    {
      variables: {
        condition: {
          member_id: { _eq: currentMemberId },
          course_content_id: options.courseContentId ? { _eq: options.courseContentId } : undefined,
          course_content: options.courseId ? { course_chapter: { course_id: { _eq: options.courseId } } } : undefined,
          is_last_open: options.courseId ? { _eq: true } : undefined,
        },
      },
    },
  )

  const courseContentProgress: CourseContentProgressProps | null = useMemo(
    () =>
      !data || data.course_content_progress.length === 0
        ? null
        : {
            id: data.course_content_progress[0].id,
            courseContentId: data.course_content_progress[0].course_content_id,
            progressPercentage: data.course_content_progress[0].progress_percentage,
            lastProgressSeconds: data.course_content_progress[0].last_progress_seconds,
            isLastOpen: data.course_content_progress[0].is_last_open,
          },
    [data],
  )
  return {
    loadingCourseContentProgress: loading,
    errorCourseContentProgress: error,
    courseContentProgress,
    refetchCourseContentProgress: refetch,
  }
}

export const useCourseContentProgresses = (options: {
  courseContentId?: string
  courseId?: string
  courseIdOrSlug?: string
  skip?: boolean
}) => {
  const { currentMemberId } = useAuth()

  const { loading, error, data, refetch } = useQuery<
    types.GET_COURSE_CONTENT_PROGRESSES,
    types.GET_COURSE_CONTENT_PROGRESSESVariables
  >(
    gql`
      query GET_COURSE_CONTENT_PROGRESSES($condition: course_content_progress_bool_exp!) {
        course_content_progress(where: $condition) {
          id
          course_content_id
          progress_percentage
          last_progress_seconds
          is_last_open
        }
      }
    `,
    {
      context: { important: true },
      skip: options.skip || currentMemberId === NIL_UUID,
      variables: {
        condition: {
          member_id: { _eq: currentMemberId },
          course_content_id: options.courseContentId ? { _eq: options.courseContentId } : undefined,
          course_content: options.courseId
            ? { course_chapter: { course_id: { _eq: options.courseId } } }
            : options.courseIdOrSlug
            ? {
                course_chapter: {
                  course_id: isUUID(options.courseIdOrSlug) ? { _eq: options.courseIdOrSlug } : undefined,
                  course: isUUID(options.courseIdOrSlug) ? undefined : { slug: { _eq: options.courseIdOrSlug } },
                },
              }
            : undefined,
        },
      },
    },
  )

  const courseContentProgresses: CourseContentProgressProps[] = useMemo(
    () =>
      !data || data.course_content_progress.length === 0
        ? []
        : data.course_content_progress.map(course_content_progress => ({
            id: course_content_progress.id,
            courseContentId: course_content_progress.course_content_id,
            progressPercentage: course_content_progress.progress_percentage,
            lastProgressSeconds: course_content_progress.last_progress_seconds,
            isLastOpen: course_content_progress.is_last_open,
          })),
    [data],
  )
  return {
    loadingCourseContentProgresses: loading,
    errorCourseContentProgresses: error,
    courseContentProgresses,
    refetchCourseContentProgress: refetch,
  }
}
