import { gql } from '@apollo/client'
import { MEMBER_PUBLIC_COLUMNS } from '@havppen/gql/src/member'

export const COURSE_PUBLIC_COLUMNS = gql`
  fragment COURSE_PUBLIC_COLUMNS on course {
    id
    title
    abstract
    position
    slug
    featured_image_url
    cover_video_url
    is_private
    is_subscription
    instructor_id
    status

    online_at
    fundraising_start_at
    fundraising_due_at
    preorder_due_at

    list_price
    sale_price
    participant_limit

    style_attributes
    metadata

    course_durations_aggregate {
      aggregate {
        sum {
          duration
        }
      }
    }

    course_discounts {
      id
      name
      type
      target_unit
      target_amount
      start_at
      end_at
      base_limit_unit
      base_limit_amount
      product_id
      created_at
    }

    is_fundraising_enable
    fundraising_pricing_mode
    fundraising_target_unit
    fundraising_target_amount
    fundraising_price
    preorder_price

    course_categories {
      category {
        id
        name
        slug
      }
    }

    course_instructors {
      id
      member_id
      member {
        ...MEMBER_PUBLIC_COLUMNS
      }
    }

    product_possession_summary {
      possession_count
    }
    course_sales {
      sum
    }

    product_review_summary {
      review_count
      average_rating
    }

    course_visibility {
      visibility_type
      purchasable_type
    }
  }
  ${MEMBER_PUBLIC_COLUMNS}
`
export const COURSE_CHAPTER_COLUMN = gql`
  fragment COURSE_CHAPTER_COLUMN on course {
    course_chapters(order_by: { position: asc }) {
      course_contents_aggregate {
        aggregate {
          sum {
            duration
            video_duration
          }
        }
      }
    }
  }
`
export const COURSE_CHAPTER_CONTENT_PUBLIC_COLUMN = gql`
  fragment COURSE_CHAPTER_CONTENT_PUBLIC_COLUMN on course {
    course_chapters(order_by: { position: asc }) {
      id
      title
      is_private
      course_content_publics(order_by: { position: asc }) {
        id
        title
        type
        duration
        video_duration
        is_private
        is_visible
        published_at
      }
    }
  }
`

export const GET_COURSE = gql`
  query GET_COURSE($condition: course_bool_exp) {
    course(where: $condition, limit: 1) {
      ...COURSE_PUBLIC_COLUMNS

      seo_attributes

      course_assistants {
        id
        member_id
        member {
          ...MEMBER_PUBLIC_COLUMNS
        }
      }

      course_instructors {
        id
        member_id
        member {
          ...MEMBER_PUBLIC_COLUMNS
        }
      }

      course_chapters {
        id
        course_content_publics_aggregate(where: { published_at: { _is_null: false } }) {
          aggregate {
            count
            sum {
              video_duration
            }
          }
        }

        course_assignment_contents_aggregate: course_content_publics_aggregate(
          where: { type: { _eq: "assignment" }, published_at: { _is_null: false } }
        ) {
          aggregate {
            count
          }
        }
        course_material_contents_aggregate: course_content_publics_aggregate(
          where: { type: { _eq: "material" }, published_at: { _is_null: false } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
  ${COURSE_PUBLIC_COLUMNS}
`

export const GET_COURSE_DETAIL = gql`
  query GET_COURSE_DETAIL($condition: course_bool_exp) {
    course(where: $condition, limit: 1) {
      id
      content
      information_data
    }
  }
`

export const GET_COURSES = gql`
  query GET_COURSES($limit: Int, $offset: Int, $condition: course_bool_exp, $orderBy: [course_order_by!]) {
    course(limit: $limit, offset: $offset, where: $condition, order_by: $orderBy) {
      ...COURSE_PUBLIC_COLUMNS
    }
    course_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
  ${COURSE_PUBLIC_COLUMNS}
`

export const GET_PURCHASED_COURSES = gql`
  query GET_PURCHASED_COURSES(
    $limit: Int
    $offset: Int
    $condition: course_bool_exp
    $orderBy: [course_order_by!]
    $memberId: String!
  ) {
    course(limit: $limit, offset: $offset, where: $condition, order_by: $orderBy) {
      ...COURSE_PUBLIC_COLUMNS

      course_member_progresses(where: { member_id: { _eq: $memberId } }) {
        total_progress_seconds
        progress_percentage
      }
      course_possessions(where: { member_id: { _eq: $memberId } }) {
        is_expired
        trial_expired_at
      }
    }
    course_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
  ${COURSE_PUBLIC_COLUMNS}
`

export const GET_COURSE_POSSESSION_COUNT = gql`
  query GET_COURSE_POSSESSION_COUNT($condition: product_possession_summary_bool_exp!) {
    product_possession_summary(where: $condition, limit: 1) {
      possession_count
    }
  }
`

export const GET_COURSE_COLLECTIONS = gql`
  query GET_COURSE_COLLECTIONS($condition: course_collection_bool_exp!, $limit: Int, $offset: Int) {
    course_collection(where: $condition, limit: $limit, offset: $offset) {
      course {
        ...COURSE_PUBLIC_COLUMNS
      }
    }
    course_collection_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
  ${COURSE_PUBLIC_COLUMNS}
`

export const GET_COURSE_BRIEF_CHAPTERS = gql`
  query GET_COURSE_BRIEF_CHAPTERS($condition: course_bool_exp) {
    course_chapter(where: { course: $condition }) {
      id
      title
      is_private
      course_id
      position
      created_at
      course_content_publics(where: { is_visible: { _eq: true }, published_at: { _lte: "now()" } }) {
        id
        title
        type
        video_duration
        duration
        is_private
        is_visible
        published_at
        position
        created_at

        file_attachments {
          id
          file_name
          file_size
          file_type
          file_content_type
        }
      }
    }
  }
`

export const GET_COURSE_CONTENT_PUBLIC_DETAILS = gql`
  query GET_COURSE_CONTENT_PUBLIC_DETAILS($condition: course_content_public_bool_exp) {
    course_content_public(where: $condition) {
      id
      content
      video_url
      caption_url
      metadata
      created_at

      course_content {
        content
        video_url
        caption_url
        metadata
        file_attachments {
          id
          file_name
          file_size
          file_type
          file_content_type
        }
      }
      course_content_timecodes {
        id
        name
        timecode
      }
    }
  }
`

export const GET_COURSE_CONTENT_DETAILS = gql`
  query GET_COURSE_CONTENT_DETAILS($condition: course_content_bool_exp) {
    course_content(where: $condition) {
      id
      content
      video_url
      caption_url
      metadata

      file_attachments {
        id
        file_name
        file_size
        file_type
        file_content_type
      }
      course_content_timecodes(order_by: { timecode: asc }) {
        id
        name
        timecode
      }
    }
  }
`

export const GET_COURSE_CHAPTER_CONTENTS = gql`
  query GET_COURSE_CHAPTER_CONTENTS(
    $condition: course_bool_exp
    $isContentVisible: Boolean!
    $isVideoVisible: Boolean!
  ) {
    course_chapter(where: { course: $condition }, order_by: { position: asc }) {
      id
      title
      is_private
      course_id
      course_content_publics(order_by: { position: asc }) {
        id
        title
        content @include(if: $isContentVisible)
        type
        video_url @include(if: $isVideoVisible)
        video_duration
        caption_url @include(if: $isVideoVisible)
        duration
        metadata
        is_private
        is_visible
        published_at
      }
    }
  }
`

export const GET_COURSE_IDS = gql`
  query GET_COURSE_IDS($limit: Int) {
    app {
      app_domains(where: { default: { _eq: true }, type: { _eq: "app" } }) {
        domain
      }
      courses(where: { status: { _eq: "published" } }, limit: $limit) {
        id
        slug
        app_id
      }
      fundraising_courses: courses(where: { status: { _eq: "fundraising" } }, limit: $limit) {
        id
        slug
        app_id
      }
    }
  }
`

export const GET_COURSE_QAS = gql`
  query GET_COURSE_QAS($condition: course_qa_bool_exp!, $limit: Int) {
    course_qa(where: { _and: [{ parent_id: { _is_null: true } }, $condition] }, limit: $limit) {
      id
      content
      created_at
      member {
        ...MEMBER_PUBLIC_COLUMNS
      }
      course_qas {
        id
        content
        created_at
        member {
          ...MEMBER_PUBLIC_COLUMNS
        }
      }
    }
  }
  ${MEMBER_PUBLIC_COLUMNS}
`

export const GET_EVALUATIONS = gql`
  query GET_EVALUATIONS($limit: Int) {
    course_evaluation(limit: $limit) {
      id
      grades
      title
      content
      created_at
      member {
        ...MEMBER_PUBLIC_COLUMNS
      }
    }
  }
  ${MEMBER_PUBLIC_COLUMNS}
`

export const GET_COURSE_CONTENTS = gql`
  query GET_COURSE_CONTENTS($condition: course_content_public_bool_exp) {
    course_content_public(where: $condition, order_by: [{ course_chapter: { position: asc } }, { position: asc }]) {
      id
      title
      abstract
      content
      type
      video_url
      video_duration
      caption_url
      duration
      position
      metadata
      is_private
      is_visible
      published_at
      created_at
      updated_at

      course_chapter {
        id
        title
        position
        is_private
        course_id
      }

      course_content {
        metadata
        content
        video_url
        caption_url

        file_attachments {
          id
          file_name
          file_size
          file_type
          file_content_type
        }
      }
    }
  }
`

export const GET_COURSE_CONTENT = gql`
  query GET_COURSE_CONTENT($courseContentId: uuid!) {
    course_content_by_pk(id: $courseContentId) {
      id
      title
      content
      type
      video_url
      video_duration
      video_width
      video_height
      caption_url
      duration
      metadata
      is_private
      is_visible
      created_at
      published_at
      position
      file_attachments {
        id
        file_name
        file_size
        file_type
        file_content_type
      }
      course_content_timecodes(order_by: { timecode: asc }) {
        id
        name
        timecode
      }
      course_chapter {
        id
        title
        position
        is_private
        course_id
        created_at
      }
    }
  }
`

export const GET_COURSE_CONTENT_BRIEF = gql`
  query GET_COURSE_CONTENT_BRIEF($courseContentId: uuid!) {
    course_content_by_pk(id: $courseContentId) {
      id
      title
      type
      video_duration
      duration
      is_private
      is_visible
      created_at
      published_at
      position

      file_attachments {
        id
        file_name
        file_size
        file_type
        file_content_type
      }
    }
  }
`

export const GET_COURSE_BRIEF = gql`
  query GET_COURSE_BRIEF($condition: course_bool_exp!) {
    course(where: $condition, limit: 1) {
      id
      title
      abstract
      position
      slug
      featured_image_url
      cover_video_url
      is_private
      is_subscription
      member_id
      app_id
      status

      course_instructors {
        id
        member_id
      }

      course_assistants {
        id
        member_id
      }
    }
  }
`

export const GET_COURSE_BRIEF_ONE = gql`
  query GET_COURSE_BRIEF_ONE($courseId: uuid!) {
    course_by_pk(id: $courseId) {
      ...COURSE_PUBLIC_COLUMNS
    }
  }
  ${COURSE_PUBLIC_COLUMNS}
`
