import { ProductDiscountProps, ProductDiscountType, ProductStatus } from '@havppen/types/src/product'

export const getDiscount = ({
  productDiscounts,
  listPrice,
  defaultSalePrice,
  defaultFundraisingPrice,
  defaultPreorderPrice,
  status,
  productDiscountId,
  quantity,
}: {
  productDiscounts: ProductDiscountProps[]
  listPrice: number
  defaultSalePrice?: number | null
  defaultFundraisingPrice?: number | null
  defaultPreorderPrice?: number | null
  status: ProductStatus
  productDiscountId?: string
  quantity?: number
}) => {
  let discountPrice = defaultSalePrice ?? null
  let discountType: ProductDiscountType | null = null

  const now = new Date()
  const availableDiscounts = productDiscounts.filter(productDiscount => {
    if (productDiscount.startAt && productDiscount.endAt) {
      return productDiscount.startAt <= now && productDiscount.endAt >= now
    } else if (productDiscount.startAt) {
      return productDiscount.startAt <= now
    } else if (productDiscount.endAt) {
      return productDiscount.endAt >= now
    }

    return true
  })

  const availableDiscount = availableDiscounts.find(productDiscount => productDiscount.type === 'discount')
  const fundraisingDiscount =
    status === 'fundraising'
      ? availableDiscounts.find(productDiscount => productDiscount.type === 'fundraising')
      : undefined
  const multipleDiscount = quantity
    ? availableDiscounts
        .filter(productDiscount => productDiscount.type === 'multipleDiscount')
        .find(productDiscount => productDiscount.baseLimitAmount === quantity)
    : undefined
  const appliedDiscount = productDiscountId
    ? availableDiscounts.find(productDiscount => productDiscount.id === productDiscountId)
    : undefined

  let discount: ProductDiscountProps | undefined = undefined
  if (appliedDiscount && appliedDiscount.type !== 'multipleDiscount') {
    discount = appliedDiscount
    discountType = appliedDiscount.type
  } else if (appliedDiscount && quantity && appliedDiscount.baseLimitAmount === quantity) {
    discount = appliedDiscount
    discountType = appliedDiscount.type
  } else if (multipleDiscount) {
    discount = multipleDiscount
    discountType = 'multipleDiscount'
  } else if (status === 'fundraising' && (fundraisingDiscount !== undefined || defaultFundraisingPrice !== undefined)) {
    discount = fundraisingDiscount
    discountType = 'fundraising'
  } else if (status === 'preorder' && defaultPreorderPrice !== undefined) {
    discountType = 'preorder'
  } else if (
    status === 'coming_soon' &&
    (defaultFundraisingPrice !== undefined || defaultPreorderPrice !== undefined)
  ) {
    discountPrice = defaultFundraisingPrice ?? defaultPreorderPrice ?? null
  } else if (availableDiscount) {
    discountType = 'discount'
    discount = availableDiscount
  }

  if (discount) {
    switch (discount.targetUnit) {
      case 'cash':
        discountPrice = discount.targetAmount ?? null
        break
      case 'percentage':
        discountPrice = Math.round(listPrice * (1 - discount.targetAmount)) ?? null
        break
    }
  } else {
    switch (discountType) {
      case 'fundraising':
        discountPrice = defaultFundraisingPrice ?? null
        break
      case 'preorder':
        discountPrice = defaultPreorderPrice ?? null
        break
    }
  }
  return { discountPrice, discountType, discountId: discount?.id, discount }
}
