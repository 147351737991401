export enum productVisibilityTypes {
  public = 'public',
  authOnly = 'auth_only',
  whitelist = 'whitelist',
}
export enum productPurchasableTypes {
  public = 'public',
  authOnly = 'auth_only',
  whitelist = 'whitelist',
}

export enum productMemberTagRestrictionTypes {
  visibility = 'visibility',
  purchasable = 'purchasable',
}
export enum productMemberTagRestrictionFilterTypes {
  include = 'include',
  exclude = 'exclude',
}
