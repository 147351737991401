'use client'

import { UserOutlined } from '@ant-design/icons'
import { Avatar } from 'antd'
import { AvatarSize } from 'antd/es/avatar/AvatarContext'
import React from 'react'
import { useApp } from 'src/contexts/AppContext'

const GeneralAvatar = React.forwardRef<
  HTMLSpanElement,
  {
    src?: string | null
    className?: string
    name?: string | null
    size?: AvatarSize
    onClick?: (e?: React.MouseEvent<HTMLElement>) => void
  }
>(({ src, size, name, className, onClick }, ref) => {
  const { defaultAvatarUrl } = useApp()

  return (
    <Avatar
      ref={ref}
      className={className}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
      onClick={onClick}
      src={src ?? defaultAvatarUrl}
      alt={name ?? ''}
      icon={src ? null : <UserOutlined />}
      size={size}
    >
      {name?.[0]}
    </Avatar>
  )
})

export default GeneralAvatar
